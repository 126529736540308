<template>
  <div>
    <v-expand-transition>
      <v-alert
        v-show="showAlert1"
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg"
      >
        What name do you want to give the layer? Don't worry. This can be
        updated later.
      </v-alert>
    </v-expand-transition>

    <div v-if="showAlert1">
      <div v-if="!submitted">
        <validation-observer ref="createLayerForm">
          <form @submit.prevent="save">
            <validation-provider>
              <v-text-field
                outlined
                color="#E5E5E5"
                v-model="layer.service_name"
                hide-details="auto"
                name="serviceName"
                single-line
                background-color="white"
                full-width
              >
                <template v-slot:append>
                  <div class="mt-n2">
                    <v-btn
                      icon
                      type="submit"
                      color="primary"
                      :disabled="!layer.service_name"
                    >
                      <v-icon>
                        {{ mdiSend }}
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-text-field>
            </validation-provider>
          </form>
        </validation-observer>
      </div>
      <div v-else class="d-flex justify-end mb-4">
        <v-list outlined rounded width="fit-content" dense>
          <v-list-item>
            {{ layer.service_name }}
          </v-list-item>
        </v-list>
      </div>
    </div>
    <v-expand-transition
      v-if="
        showAlert1 &&
        submitted &&
        ['U', 'F', 'L', 'S', 'T'].includes(layer.service_type)
      "
    >
      <v-alert
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg"
      >
        Done! The {{ selectedServiceTypeLabel }} "{{ layer.service_name }}" has
        been created.
      </v-alert>
    </v-expand-transition>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { mdiSend } from "@mdi/js";
import sleep from "@/mixins/sleep";
import axios from "axios";

const serviceTypeOptions = [
  { label: "UtiliSync Layer", value: "U" },
  { label: "811 Tickets Layer", value: "L" },
  { label: "ArcGIS Feature Service", value: "F" },
  { label: "ArcGIS Map Service", value: "S" },
];

export default {
  name: "Step2NameLayer",
  props: {
    layerObj: Object,
    wmsData: Object,
  },
  data() {
    return {
      layer: {
        service_name: "",
      },
      mdiSend,
      submitted: false,
      showAlert1: false,
      serviceTypeOptions,
    };
  },
  computed: {
    selectedServiceTypeLabel() {
      return this.serviceTypeOptions.find(
        (o) => o.value === this.layer.service_type
      )?.label;
    },
  },
  async beforeMount() {
    this.layer = cloneDeep(this.layerObj);

    await sleep(500);
    this.showAlert1 = true;
  },
  methods: {
    async save() {
      const success = await this.$refs.createLayerForm.validate();
      if (!success) {
        return;
      }
      this.submitted = true;
      this.$emit("layer-saved", this.layer);
    },
  },
  watch: {
    layerObj: {
      deep: true,
      async handler(val) {
        this.layer = {
          ...this.layer,
          ...val,
        };
        if (
          (this.layer.service_type === "F" ||
            this.layer.service_type === "S") &&
          this.layer.service_url !== "" &&
          this.layer.service_name === ""
        ) {
          const { data } = await axios.get(this.layer.service_url, {
            params: {
              f: "pjson",
              token:
                this.layer.token_type !== "NONE"
                  ? localStorage.getItem("esri_token")
                  : undefined,
            },
          });

          this.layer.service_name =
            this.layer.service_type === "F"
              ? data.name
              : data.documentInfo?.Title;
        } else if (this.layer.service_type === "W") {
          if (!this.layer.service_name) {
            this.layer.service_name =
              this.wmsData?.WMS_Capabilities?.Service?.Title?._text;
          }
        }
      },
    },
    wmsData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.layer.service_type === "W" && !this.layer.service_name) {
          this.layer.service_name =
            val?.WMS_Capabilities?.Service?.Title?._text;
        }
      },
    },
  },
};
</script>

<style scoped>
.v-alert {
  margin-bottom: 8px !important;
}
</style>

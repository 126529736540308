var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showEditUtiliSyncFieldDialog,
        "max-width": "600px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              ref: "toolbar",
              staticClass: "elevation-0",
              attrs: { dark: "", color: "primary" },
            },
            [
              _c("v-toolbar-title", [_vm._v("Edit UtiliSync Field")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("edit-utilisync-field-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "py-5",
              style: {
                "background-color": "#f1f2f1",
                height: _vm.$vuetify.breakpoint.xsOnly
                  ? `${_vm.contentHeight}px`
                  : "auto",
                "overflow-y": "auto",
                "max-height": _vm.$vuetify.breakpoint.xsOnly
                  ? undefined
                  : "60vh",
              },
            },
            [
              _c(
                "section",
                [
                  _c("p", { staticClass: "caption mb-0 pb-0" }, [
                    _vm._v("Field Settings"),
                  ]),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "validation-observer",
                            { ref: "gisDataFieldForm" },
                            [
                              _c(
                                "form",
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Field Name",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Field Name",
                                                "hide-details": "auto",
                                                name: "name",
                                                "error-messages": errors,
                                                success: valid,
                                              },
                                              on: {
                                                blur: function ($event) {
                                                  return _vm.$emit(
                                                    "field-name-set",
                                                    _vm.gisDataField.name
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.gisDataField.name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.gisDataField,
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression: "gisDataField.name",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("validation-provider", {
                                    attrs: { name: "Type", rules: "required" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-select", {
                                              attrs: {
                                                label: "Type",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "type",
                                                items: _vm.typeChoices,
                                                "item-text": "label",
                                                "item-value": "value",
                                              },
                                              model: {
                                                value: _vm.gisDataField.type,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.gisDataField,
                                                    "type",
                                                    $$v
                                                  )
                                                },
                                                expression: "gisDataField.type",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("v-switch", {
                                    staticClass: "mb-0 pb-0",
                                    attrs: {
                                      label:
                                        "User Editable. The field can be manually updated by the user.",
                                    },
                                    model: {
                                      value: _vm.gisDataField.editable,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.gisDataField,
                                          "editable",
                                          $$v
                                        )
                                      },
                                      expression: "gisDataField.editable",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.gisDataField.type === "string"
                ? _c(
                    "section",
                    [
                      _c("p", { staticClass: "caption mt-3 mb-0 pb-0" }, [
                        _vm._v("Field Options"),
                      ]),
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("EditGisDataFieldOptionsForm", {
                                attrs: {
                                  gisDataFieldId:
                                    _vm.gisDataField.gis_data_field_id,
                                  gisDataFieldOptions: _vm.gisDataFieldOptions,
                                },
                                on: {
                                  "gis-field-options-added": function ($event) {
                                    _vm.gisDataFieldOptions = $event
                                  },
                                  "gis-field-options-deleted": function (
                                    $event
                                  ) {
                                    _vm.gisDataFieldOptions = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "v-card-actions",
            { ref: "cardAction", staticClass: "d-flex justify-end pa-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", dark: "" },
                  on: { click: _vm.saveField },
                },
                [_vm._v("Save Field")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
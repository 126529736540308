<template>
  <v-app>
    <v-main fluid tag="section" class="pt-0 page-background mainArea">
      <TopBar title="Layers" />
      <PageLayout>
        <v-data-table
          :headers="headers"
          :items="filteredLayers"
          hide-default-footer
          :search="search"
          :custom-filter="filterLayer"
          v-model="activeLayer"
          class="cursor-pointer"
          disable-pagination
          item-key="map_service_id"
          @click:row="rowClick"
          single-select
          style="overflow-x: hidden !important"
        >
          <template v-slot:top>
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-space-between align-end gap px-2">
                  <v-text-field
                    v-model="search"
                    label="Search"
                    hide-details="auto"
                    color="primary"
                    name="search"
                  >
                    <template v-slot:append>
                      <v-icon>{{ mdiMagnify }}</v-icon>
                    </template>
                  </v-text-field>

                  <div>
                    <v-btn
                      color="primary"
                      @click="showCreateLayerDialog = true"
                      id="addLayerBtn"
                    >
                      + Layer
                    </v-btn>
                  </div>
                </div>
              </v-col>

              <v-col cols="12" class="px-3 pt-0 pb-2 my-0 d-flex justify-start">
                <v-chip
                  class="d-flex"
                  @click="showActiveLayers = !showActiveLayers"
                >
                  <div
                    class="chip overflow-hidden text-truncate my-0 py-0 cursor-pointer"
                  >
                    {{ showActiveLayers ? "Active" : "Archived" }}
                    <v-icon small>
                      {{ mdiSyncCircle }}
                    </v-icon>
                  </div>
                </v-chip>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.service_name`]="{ item }">
            <div v-if="item.children" class="py-3">
              <div class="py-1">{{ item.service_name }}</div>
              <div
                v-for="child of item.children"
                :key="child.map_service_id"
                class="d-flex pl-2 py-0"
              >
                <v-icon class="mr-2 mt-n3">{{ mdiAlphaL }}</v-icon>
                <div>{{ child.service_name }}</div>
              </div>
            </div>
            <div v-else>{{ item.service_name }}</div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="primary">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list v-if="item.is_active">
                <v-list-item
                  class="cursor-pointer"
                  @click="openEditLayerDialog(item, true)"
                >
                  <v-icon class="mr-1">
                    {{ mdiPencil }}
                  </v-icon>
                  Edit Layer
                </v-list-item>

                <v-list-item
                  class="cursor-pointer"
                  @click="updateLayer(item, false)"
                >
                  <v-icon class="mr-1">
                    {{ mdiArchiveArrowDown }}
                  </v-icon>
                  Archive Layer
                </v-list-item>

                <v-list-item
                  class="cursor-pointer"
                  @click="
                    showConfirmDeleteLayerDialog = true;
                    layerToDelete = item.map_service_id;
                  "
                >
                  <v-icon class="mr-1">
                    {{ mdiDelete }}
                  </v-icon>
                  Delete Layer
                </v-list-item>
              </v-list>
              <v-list v-else>
                <v-list-item
                  class="cursor-pointer"
                  @click="updateLayer(item, true)"
                >
                  <v-icon class="mr-1">
                    {{ mdiArchiveArrowUp }}
                  </v-icon>
                  Make Active
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <v-dialog
          v-model="showCreateLayerDialog"
          max-width="650px"
          persistent
          :fullscreen="$vuetify.breakpoint.xsOnly"
        >
          <CreateLayerForm
            v-if="showCreateLayerDialog"
            @create-layer-form-close="
              showCreateLayerDialog = false;
              getLayers();
            "
            @create-layer-form-submitted="
              showCreateLayerDialog = false;
              getLayers();
            "
            @layer-not-created="showCreateLayerDialog = false"
          />
        </v-dialog>

        <v-dialog
          v-model="showConfirmDeleteLayerDialog"
          max-width="600px"
          :fullscreen="$vuetify.breakpoint.xsOnly"
        >
          <v-card>
            <v-list-item>
              <v-list-item-content class="pl-3">
                <div class="text-h5 py-3">Delete Layer?</div>
                Are you sure you want to delete this layer? This action cannot
                be undone.
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <div class="d-flex justify-end">
                  <v-btn
                    text
                    @click="
                      showConfirmDeleteLayerDialog = false;
                      layerToDelete = undefined;
                    "
                    color="primary"
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="primary" @click="deleteLayer(layerToDelete)">
                    Delete Layer
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="showEditLayerDialog"
          max-width="600px"
          persistent
          style="background-color: #f1f2f1"
          :fullscreen="$vuetify.breakpoint.xsOnly"
        >
          <EditLayerForm
            v-if="showEditLayerDialog"
            @edit-layer-form-close="
              showEditLayerDialog = false;
              resetURL();
              getLayers();
            "
            @edit-layer-form-submitted="
              showEditLayerDialog = false;
              resetURL();
              getLayers();
            "
            :selectedLayer="selectedLayer"
          />
        </v-dialog>

        <LayerCannotBeDeletedDialog
          :showLayerCannotBeDeletedDialog="showLayerCannotBeDeletedDialog"
          @cancel="showLayerCannotBeDeletedDialog = false"
        />
      </PageLayout>
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "@/components/app/TopBar.vue";
import CreateLayerForm from "@/components/layers/CreateLayerForm.vue";
import EditLayerForm from "@/components/layers/EditLayerForm.vue";
import PageLayout from "@/components/layouts/PageLayout.vue";
import {
  mdiMagnify,
  mdiDelete,
  mdiPencil,
  mdiArchiveArrowUp,
  mdiArchiveArrowDown,
  mdiSyncCircle,
  mdiAlphaL,
} from "@mdi/js";
import axios from "axios";
import usetifulMixin from "@/mixins/usetifulMixin";
import LayerCannotBeDeletedDialog from "@/components/layers/LayerCannotBeDeletedDialog.vue";
import { mapMutations } from "vuex";

const APIURL = process.env.VUE_APP_API_URL;
const headers = [
  {
    text: "Layer Name",
    align: "start",
    sortable: false,
    value: "service_name",
  },
  {
    text: "Actions",
    align: "end",
    sortable: false,
    value: "actions",
  },
];

export default {
  name: "Layers",
  components: {
    TopBar,
    CreateLayerForm,
    EditLayerForm,
    PageLayout,
    LayerCannotBeDeletedDialog,
  },
  mixins: [usetifulMixin],
  data() {
    return {
      search: "",
      mdiMagnify,
      mdiPencil,
      mdiDelete,
      mdiArchiveArrowUp,
      mdiArchiveArrowDown,
      mdiSyncCircle,
      mdiAlphaL,
      layers: [],
      showCreateLayerDialog: false,
      showEditLayerDialog: false,
      showConfirmDeleteLayerDialog: false,
      layerToDelete: undefined,
      selectedLayer: {},
      headers,
      isActive: [],
      showActiveLayers: true,
      showLayerCannotBeDeletedDialog: false,
      activeLayer: undefined,
    };
  },
  computed: {
    filteredLayers() {
      if (this.showActiveLayers) {
        return this.layers.filter((l) => l.is_active && l.service_type !== "R");
      } else {
        return this.layers.filter(
          (l) => !l.is_active && l.service_type !== "R"
        );
      }
    },
  },
  methods: {
    ...mapMutations(["setMenuState"]),
    rowClick(item, row) {
      row.select(true);
      this.openEditLayerDialog(item, true);
    },
    async updateLayer(item, isActive = true) {
      await axios.put(`${APIURL}/map_services/${item.map_service_id}`, {
        ...item,
        is_active: isActive,
      });
      await axios.delete(
        `${APIURL}/map_services/remove_map_service_from_map/${item.map_service_id}`
      );
      await this.getLayers();
    },
    openEditLayerDialog(item, pushRouter) {
      this.selectedLayer = item;
      this.highlightedRowId = [item.map_service_id];
      this.showEditLayerDialog = true;
      if (pushRouter) {
        this.$router.push({
          path: "/layers",
          query: {
            layer: item.map_service_id,
          },
        });
      }
    },
    resetURL() {
      this.$router.push({
        path: "/layers",
      });
    },
    async getLayers() {
      const { user_group_id: userGroupId } = JSON.parse(
        localStorage.getItem("auth")
      );
      const {
        data: { results },
      } = await axios.get(
        `${APIURL}/map_services?user_group_id=${userGroupId}`
      );

      this.layers = results;

      // Get children associations
      const layersMap = new Map(
        this.layers.map((layer) => [layer.map_service_id, layer])
      );

      this.layers = this.layers.filter((layer) => {
        if (layer.parent_map_service_id) {
          const parentLayer = layersMap.get(layer.parent_map_service_id);
          if (parentLayer) {
            if (!parentLayer.children) {
              parentLayer.children = [];
            }
            parentLayer.children.push(layer);
            return false;
          }
        }
        return true;
      });
    },
    async deleteLayer(mapServiceId) {
      try {
        const {
          data: {
            results: { renderer },
          },
        } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
        const { renderer_id: rendererId } = renderer;
        if (rendererId) {
          await axios.delete(`${APIURL}/renderer_symbol/all/${rendererId}`);
        }
        const {
          data: { results: gisDataFields },
        } = await axios.get(`${APIURL}/gis_data_fields`, {
          params: {
            map_service_id: mapServiceId,
          },
        });
        const gisDataFieldOptions = gisDataFields
          .map((f) => f.gis_data_field_options)
          .flat();
        await Promise.all(
          gisDataFieldOptions.map((o) =>
            axios.delete(
              `${APIURL}/gis_data_field_options/${o.gis_data_field_option_id}`
            )
          )
        );
        await Promise.all(
          gisDataFields.map((f) =>
            axios.delete(`${APIURL}/gis_data_fields/${f.gis_data_field_id}`)
          )
        );
        this.showConfirmDeleteLayerDialog = false;
        await axios.delete(`${APIURL}/map_services/${mapServiceId}`);
      } catch (error) {
        this.showLayerCannotBeDeletedDialog = true;
      } finally {
        await this.getLayers();
      }
    },
    filterLayer(value, search) {
      return (
        typeof value === "string" &&
        typeof search === "string" &&
        value.toString().toLowerCase().includes(search.toLowerCase())
      );
    },
  },
  watch: {
    "$route.query.layer": {
      immediate: true,
      deep: true,
      async handler() {
        if (this.$route.query.layer) {
          if (this.layers.length === 0) {
            await this.getLayers();
          }
          const selectedLayer = this.layers.find((layer) => {
            return layer.map_service_id === this.$route.query.layer;
          });
          this.openEditLayerDialog(selectedLayer);
        }
      },
    },
    "$route.query.form": {
      immediate: true,
      deep: true,
      async handler() {
        const formDefId = this.$route.query.form;
        if (formDefId) {
          this.setMenuState(false);
        }
      },
    },
  },
  beforeMount() {
    this.getLayers();
  },
};
</script>

<style scoped>
.gap {
  gap: 10px;
}

tr.v-data-table__selected::v-deep {
  background: #f5f5f5 !important;
}
</style>

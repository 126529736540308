var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showAlert1,
                  expression: "showAlert1",
                },
              ],
              staticClass: "rounded-tl-lg rounded-tr-lg rounded-br-lg",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [
              _vm._v(
                " What name do you want to give the layer? Don't worry. This can be updated later. "
              ),
            ]
          ),
        ],
        1
      ),
      _vm.showAlert1
        ? _c("div", [
            !_vm.submitted
              ? _c(
                  "div",
                  [
                    _c("validation-observer", { ref: "createLayerForm" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.save.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "validation-provider",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  color: "#E5E5E5",
                                  "hide-details": "auto",
                                  name: "serviceName",
                                  "single-line": "",
                                  "background-color": "white",
                                  "full-width": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "mt-n2" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    type: "submit",
                                                    color: "primary",
                                                    disabled:
                                                      !_vm.layer.service_name,
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.mdiSend) +
                                                        " "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2279318630
                                ),
                                model: {
                                  value: _vm.layer.service_name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.layer, "service_name", $$v)
                                  },
                                  expression: "layer.service_name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "d-flex justify-end mb-4" },
                  [
                    _c(
                      "v-list",
                      {
                        attrs: {
                          outlined: "",
                          rounded: "",
                          width: "fit-content",
                          dense: "",
                        },
                      },
                      [
                        _c("v-list-item", [
                          _vm._v(" " + _vm._s(_vm.layer.service_name) + " "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ])
        : _vm._e(),
      _vm.showAlert1 &&
      _vm.submitted &&
      ["U", "F", "L", "S", "T"].includes(_vm.layer.service_type)
        ? _c(
            "v-expand-transition",
            [
              _c(
                "v-alert",
                {
                  staticClass: "rounded-tl-lg rounded-tr-lg rounded-br-lg",
                  attrs: { color: "primary", dark: "", width: "85%" },
                },
                [
                  _vm._v(
                    " Done! The " +
                      _vm._s(_vm.selectedServiceTypeLabel) +
                      ' "' +
                      _vm._s(_vm.layer.service_name) +
                      '" has been created. '
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showAlert1,
                  expression: "showAlert1",
                },
              ],
              staticClass: "rounded-tl-lg rounded-tr-lg rounded-br-lg",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [
              _vm._v(
                " Do you want to change the default symbology for your Layer? "
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-end mb-5" },
        [
          !_vm.changeSymbologyChoice
            ? _c(
                "v-list",
                {
                  staticClass: "py-0",
                  style: {
                    width: "fit-content",
                    "border-radius": "1%",
                    border: "1px solid primary",
                  },
                  attrs: { dense: "" },
                },
                _vm._l(_vm.changeSymbologyOptions, function (c, i) {
                  return _c(
                    "v-list-item",
                    {
                      key: c.label,
                      staticClass: "my-0 py-0",
                      style: {
                        "border-bottom":
                          i !== _vm.changeSymbologyOptions.length - 1
                            ? "1px solid primary"
                            : "",
                      },
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onChoiceClick(c.label)
                        },
                      },
                    },
                    [_c("span", [_vm._v(" " + _vm._s(c.label) + " ")])]
                  )
                }),
                1
              )
            : _c(
                "v-list",
                { attrs: { outlined: "", rounded: "", width: "fit-content" } },
                [
                  _c("v-list-item", [
                    _vm._v(
                      " " + _vm._s(_vm.selectedChangeSymbologyChoiceLabel) + " "
                    ),
                  ]),
                ],
                1
              ),
          _vm.showCustomLayerSymbologyDialog
            ? _c("CustomLayerSymbologyDialog", {
                attrs: {
                  showCustomLayerSymbologyDialog:
                    _vm.showCustomLayerSymbologyDialog,
                  mapServiceId: _vm.mapServiceId,
                  layerObj: _vm.layer,
                },
                on: {
                  "custom-symbology-dialog-close": function ($event) {
                    _vm.showCustomLayerSymbologyDialog = false
                    _vm.symbologySaved = true
                    _vm.$emit("layer-saved")
                  },
                  "symbology-saved": function ($event) {
                    _vm.showCustomLayerSymbologyDialog = false
                    _vm.symbologySaved = true
                    _vm.$emit("layer-saved")
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.symbologySaved,
                  expression: "symbologySaved",
                },
              ],
              staticClass: "rounded-tl-lg rounded-tr-lg rounded-br-lg",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [
              _vm._v(
                " Your symbology has been set! You can always change this later if needed. "
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.symbologySaved,
                  expression: "symbologySaved",
                },
              ],
              staticClass: "rounded-tl-lg rounded-tr-lg rounded-br-lg",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [
              _vm._v(
                " Thanks for using the Create Layer UtiliBot to create your layer! "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <div
      class="d-flex justify-end"
      v-if="displayFeaturesUsing === 'class_break' && fieldToReference"
    >
      <v-btn
        text
        @click="showAddValueRangeDialog = true"
        :disabled="!fieldToReference"
        color="primary"
      >
        + Add Value Range
      </v-btn>
    </div>
    <v-card class="mt-5">
      <v-card-text class="ma-0 pa-0">
        <AddValueRangeDialog
          :showAddValueRangeDialog="showAddValueRangeDialog"
          @add-value-range-dialog-close="showAddValueRangeDialog = false"
          @range-set="onRangeSet"
        />

        <EditValueRangeDialog
          :showEditValueRangeDialog="showEditValueRangeDialog"
          :selectedRendererSymbolIndex="selectedRendererSymbolIndex"
          @edit-value-range-dialog-close="showEditValueRangeDialog = false"
          :selectedRendererSymbol="selectedRendererSymbol"
          @range-edited="onRangeEdited"
        />

        <v-expansion-panels multiple accordion>
          <v-expansion-panel
            v-for="(rendererSymbol, index) of rendererSymbols"
            :key="rendererSymbol.renderer_symbol_id"
          >
            <v-expansion-panel-header class="px-3 py-2" hide-actions>
              <validation-observer ref="gisDataFieldForm">
                <div
                  class="d-flex align-center"
                  :class="
                    displayFeaturesUsing === 'simple'
                      ? ''
                      : 'justify-space-between'
                  "
                >
                  <div class="d-flex">
                    <div class="mr-5">
                      <p class="caption ml-3 py-0 my-0">
                        {{ index === 0 ? "Symbol" : "&nbsp;" }}
                      </p>
                      <v-btn
                        class="pa-0 d-flex align-center"
                        text
                        :height="
                          ['basic'].includes(rendererSymbol.symbol_type)
                            ? rendererSymbol.picture_size
                            : undefined
                        "
                      >
                        <img
                          v-if="['shape'].includes(rendererSymbol.symbol_type)"
                          :src="
                            require(`@/assets/img/mapSymbols/${findColorValue(
                              rendererSymbol.fill_color
                            )}${symbolChoices[index]}.png`)
                          "
                          :style="{
                            width: `${rendererSymbol.picture_size}px`,
                            height: `${rendererSymbol.picture_size}px`,
                          }"
                        />
                        <div
                          class="my-6"
                          v-else-if="
                            ['basic'].includes(rendererSymbol.symbol_type)
                          "
                          :style="{
                            'background-color': rendererSymbol.fill_color,
                            'border-radius': '50%',
                            border: `${rendererSymbol.width}px solid ${rendererSymbol.outline_color}`,
                            width: `${
                              rendererSymbol.picture_size - rendererSymbol.width
                            }px`,
                            height: `${
                              rendererSymbol.picture_size - rendererSymbol.width
                            }px`,
                          }"
                        ></div>
                        <template
                          v-else-if="
                            ['image'].includes(rendererSymbol.symbol_type)
                          "
                        >
                          <div class="mt-2">
                            <EditSymbolsFormImage
                              :rendererSymbol="rendererSymbol"
                            />
                          </div>
                        </template>
                        <div class="mt-1">
                          <v-icon class="my-0 py-0">
                            {{ mdiChevronDown }}
                          </v-icon>
                        </div>
                      </v-btn>
                    </div>

                    <div v-if="displayFeaturesUsing !== 'simple'">
                      <p class="caption my-0">
                        {{ index === 0 ? "Label" : "&nbsp;" }}
                      </p>
                      <div class="d-flex align-center flex-grow-1 py-3">
                        {{ rendererSymbol.label }}
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="displayFeaturesUsing === 'class_break'"
                    class="d-flex align-center"
                  >
                    <div class="mr-3 value">
                      <p class="caption my-0">
                        {{ index === 0 ? "Min. Value" : "&nbsp;" }}
                      </p>
                      <div class="d-flex align-center flex-grow-1 py-3">
                        {{ rendererSymbol.min_value }}
                      </div>
                    </div>
                    <div class="value">
                      <p class="caption my-0">
                        {{ index === 0 ? "Max. Value" : "&nbsp;" }}
                      </p>
                      <div class="d-flex align-center flex-grow-1 py-3">
                        {{ rendererSymbol.max_value }}
                      </div>
                    </div>

                    <div class="d-flex align-stretch align-center">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="pa-0" text v-bind="attrs" v-on="on">
                            <v-icon class="my-0 py-0">
                              {{ mdiDotsVertical }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="openEditValueRangeDialog(index)">
                            <v-list-item-title
                              class="d-flex align-center menu-item"
                            >
                              <v-icon class="mr-2" color="primary">
                                {{ mdiPencil }}
                              </v-icon>
                              Edit
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="deleteRendererSymbol(index)">
                            <v-list-item-title
                              class="d-flex align-center menu-item"
                            >
                              <v-icon class="mr-2" color="primary">
                                {{ mdiDelete }}
                              </v-icon>
                              Delete
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>
                  <div
                    class="d-flex align-stretch align-self-stretch"
                    v-else-if="displayFeaturesUsing === 'simple'"
                  >
                    <div class="d-flex align-end pb-1">
                      One symbol is displayed for all features
                    </div>
                  </div>
                </div>
              </validation-observer>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0">
              <div>
                <validation-observer ref="gisDataFieldForm">
                  <form class="d-flex justify-space-between">
                    <div class="d-flex">
                      <div class="pr-3">
                        <validation-provider
                          v-slot="{ errors, valid }"
                          name="Symbol Type"
                          rules="required"
                        >
                          <v-select
                            label="Symbol Type"
                            hide-details="auto"
                            :error-messages="errors"
                            :success="valid"
                            color="primary"
                            name="symbolType"
                            :items="symbolTypeChoices"
                            item-text="label"
                            item-value="value"
                            v-model="rendererSymbol.symbol_type"
                            style="width: 100px"
                            @change="
                              onRenderSymbolChange(index);
                              $emit(
                                'renderer-symbols-changed',
                                rendererSymbols
                              );
                            "
                          >
                          </v-select>
                        </validation-provider>
                      </div>

                      <div
                        class="d-flex align-center"
                        v-if="['shape'].includes(rendererSymbol.symbol_type)"
                      >
                        <div>
                          <div
                            class="mr-2 font-weight-regular caption mb-2"
                            :style="{
                              color: rendererSymbol.fill_color
                                ? '#4caf50'
                                : undefined,
                            }"
                          >
                            Color
                          </div>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="pa-0 d-flex"
                                text
                                v-bind="attrs"
                                v-on="on"
                                x-small
                              >
                                <div
                                  :style="{
                                    'background-color':
                                      rendererSymbol.fill_color,
                                    width: '25px',
                                    height: '25px',
                                    border: '1px solid black',
                                  }"
                                >
                                  &nbsp;
                                </div>

                                <v-icon class="my-0 py-0">
                                  {{ mdiChevronDown }}
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="choice of colorChoices"
                                :key="choice.value"
                                @click="
                                  rendererSymbol.fill_color =
                                    choice.actualColor;
                                  rendererSymbol.url = updateSymbolUrl(
                                    rendererSymbol,
                                    index
                                  );
                                  $emit(
                                    'renderer-symbols-changed',
                                    rendererSymbols
                                  );
                                "
                              >
                                <v-list-item-title class="d-flex align-center">
                                  <div
                                    :style="{
                                      'background-color': choice.actualColor,
                                      width: '25px',
                                      height: '25px',
                                    }"
                                    class="mr-2"
                                  >
                                    &nbsp;
                                  </div>
                                  <div>{{ choice.label }}</div>
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>

                        <div>
                          <div
                            class="ml-2 mr-2 font-weight-regular caption mt-n2"
                            :style="{
                              color: rendererSymbol.url ? '#4caf50' : undefined,
                            }"
                          >
                            Shape
                          </div>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="pa-0 d-flex pt-2"
                                text
                                v-bind="attrs"
                                v-on="on"
                                x-small
                              >
                                <img
                                  :src="
                                    require(`@/assets/img/mapSymbols/${findColorValue(
                                      rendererSymbol.fill_color
                                    )}${symbolChoices[index]}.png`)
                                  "
                                />
                                <v-icon class="my-0 py-0">
                                  {{ mdiChevronDown }}
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="symbol of symbols"
                                :key="symbol"
                                @click="
                                  $set(symbolChoices, index, symbol);
                                  rendererSymbol.url = `/img/mapSymbols/${findColorValue(
                                    rendererSymbol.fill_color
                                  )}${symbol}.png`;
                                  $emit(
                                    'renderer-symbols-changed',
                                    rendererSymbols
                                  );
                                "
                              >
                                <v-list-item-title class="d-flex align-center">
                                  <img
                                    :src="
                                      require(`@/assets/img/mapSymbols/${findColorValue(
                                        rendererSymbol.fill_color
                                      )}${symbol}.png`)
                                    "
                                  />
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                      </div>

                      <div
                        class="d-flex"
                        v-else-if="
                          ['basic'].includes(rendererSymbol.symbol_type)
                        "
                      >
                        <div>
                          <div
                            class="mr-2 font-weight-regular caption"
                            :style="{
                              color: rendererSymbol.fill_color
                                ? '#4caf50'
                                : undefined,
                            }"
                          >
                            Fill Color
                          </div>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="pa-0 d-flex"
                                text
                                v-bind="attrs"
                                v-on="on"
                              >
                                <div
                                  :style="{
                                    'background-color':
                                      rendererSymbol.fill_color,
                                    width: '25px',
                                    height: '25px',
                                    border: '1px solid black',
                                  }"
                                  class="mr-2"
                                >
                                  &nbsp;
                                </div>

                                <v-icon class="my-0 py-0">
                                  {{ mdiChevronDown }}
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item>
                                <v-list-item-title class="d-flex align-center">
                                  <v-color-picker
                                    dot-size="25"
                                    width="230px"
                                    show-swatches
                                    v-model="rendererSymbol.fill_color"
                                    @input="
                                      $emit(
                                        'renderer-symbols-changed',
                                        rendererSymbols
                                      )
                                    "
                                  >
                                  </v-color-picker>
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>

                        <div style="width: 100px">
                          <div
                            class="mr-2 font-weight-regular caption"
                            :style="{
                              color: rendererSymbol.fill_color
                                ? '#4caf50'
                                : undefined,
                            }"
                          >
                            Outline Color
                          </div>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="pa-0 d-flex"
                                text
                                v-bind="attrs"
                                v-on="on"
                              >
                                <div
                                  :style="{
                                    'background-color':
                                      rendererSymbol.outline_color,
                                    width: '25px',
                                    height: '25px',
                                    border: '1px solid black',
                                  }"
                                  class="mr-2"
                                >
                                  &nbsp;
                                </div>

                                <v-icon class="my-0 py-0">
                                  {{ mdiChevronDown }}
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item>
                                <v-list-item-title class="d-flex align-center">
                                  <v-color-picker
                                    dot-size="15"
                                    width="230px"
                                    show-swatches
                                    v-model="rendererSymbol.outline_color"
                                    @input="
                                      $emit(
                                        'renderer-symbols-changed',
                                        rendererSymbols
                                      )
                                    "
                                  ></v-color-picker>
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                      </div>
                    </div>
                    <div class="slider">
                      <div
                        v-if="
                          ['basic', 'shape', 'image'].includes(
                            rendererSymbol.symbol_type
                          )
                        "
                        class="d-flex align-center"
                      >
                        <v-slider
                          label="Symbol Size"
                          :max="64"
                          :min="0"
                          hide-details
                          v-model="rendererSymbol.picture_size"
                          @change="
                            $emit('renderer-symbols-changed', rendererSymbols)
                          "
                        >
                          <template v-slot:append>
                            <v-text-field
                              v-model="rendererSymbol.picture_size"
                              readonly
                              class="number-input my-0 py-0 mt-n2"
                              hide-details
                            />
                          </template>
                        </v-slider>
                      </div>

                      <div
                        v-if="['basic'].includes(rendererSymbol.symbol_type)"
                        class="d-flex align-center"
                      >
                        <v-slider
                          label="Outline Width"
                          :max="10"
                          :min="0"
                          hide-details
                          v-model="rendererSymbol.width"
                          @change="
                            $emit('renderer-symbols-changed', rendererSymbols)
                          "
                        >
                          <template v-slot:append>
                            <v-text-field
                              v-model="rendererSymbol.width"
                              readonly
                              class="number-input my-0 py-0 mt-n2"
                              hide-details
                            />
                          </template>
                        </v-slider>
                      </div>
                    </div>
                  </form>
                </validation-observer>
              </div>
              <div
                class="d-flex align-center mt-4"
                v-if="['image'].includes(rendererSymbol.symbol_type)"
              >
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Image URL"
                  rules="required"
                  style="width: 100%"
                >
                  <v-text-field
                    label="Image URL"
                    hide-details="auto"
                    :error-messages="errors"
                    :success="valid"
                    color="primary"
                    name="imageUrl"
                    v-model="rendererSymbol.url"
                    @change="$emit('renderer-symbols-changed', rendererSymbols)"
                  >
                  </v-text-field>
                </validation-provider>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiChevronDown, mdiDotsVertical, mdiDelete, mdiPencil } from "@mdi/js";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import AddValueRangeDialog from "@/components/layers/create-layer-form/step-5-symbology/custom-layer-symbology-dialog/symbols-form/AddValueRangeDialog";
import EditValueRangeDialog from "@/components/layers/create-layer-form/step-5-symbology/custom-layer-symbology-dialog/symbols-form/EditValueRangeDialog";
import EditSymbolsFormImage from "@/components/layers/edit-layer-form/shared/layer-details/edit-custom-layer-symbology-dialog/edit-symbols-form/EditSymbolsFormImage";
import generateSingleSymbol from "@/mixins/generateSingleSymbol";

const APIURL = process.env.VUE_APP_API_URL;

const symbols = [
  "CircleLargeB",
  "DiamondLargeB",
  "Pin1LargeB",
  "Pin2LargeB",
  "SquareLargeB",
  "StarLargeB",
];
const symbolTypeChoices = [
  { label: "Shape", value: "shape" },
  { label: "Basic", value: "basic" },
  { label: "Image URL", value: "image" },
];
const colorChoices = [
  { label: "Black", value: "Black", actualColor: "black" },
  { label: "Blue", value: "Blue", actualColor: "primary" },
  { label: "Green", value: "Green", actualColor: "green" },
  { label: "Orange", value: "Orange", actualColor: "orange" },
  { label: "Purple", value: "Purple", actualColor: "purple" },
  { label: "Red", value: "Red", actualColor: "red" },
  { label: "Yellow", value: "Yellow", actualColor: "yellow" },
];

export default {
  name: "SymbolsForm",
  props: {
    mapServiceId: String,
    rendererId: String,
    displayFeaturesUsing: String,
    fieldToReference: String,
  },
  components: {
    AddValueRangeDialog,
    EditValueRangeDialog,
    EditSymbolsFormImage,
  },
  data() {
    return {
      mdiChevronDown,
      mdiDotsVertical,
      mdiDelete,
      mdiPencil,
      symbolChoices: [],
      gisDataFieldOptions: [],
      symbols,
      symbolTypeChoices,
      rendererSymbols: [],
      colorChoices,
      showAddValueRangeDialog: false,
      selectedRendererSymbol: {},
      showEditValueRangeDialog: false,
      selectedRendererSymbolIndex: undefined,
      gisDataFields: [],
    };
  },
  watch: {
    displayFeaturesUsing: {
      immediate: true,
      async handler(val) {
        this.rendererSymbols = [];
        if (val === "unique_value") {
          await this.getGisDataFieldOptions();
        } else if (val === "simple") {
          generateSingleSymbol();
        }
        this.$emit("renderer-symbols-changed", this.rendererSymbols);
      },
    },
    fieldToDisplay: {
      immediate: true,
      async handler(val) {
        if (!val) {
          return;
        }
        if (this.displayFeaturesUsing === "unique_value") {
          await this.getGisDataFieldOptions();
        } else if (this.displayFeaturesUsing === "simple") {
          generateSingleSymbol();
        }
      },
    },
    fieldToReference: {
      immediate: true,
      async handler(val) {
        if (!val) {
          return;
        }
        if (this.displayFeaturesUsing === "unique_value") {
          await this.getGisDataFieldOptions();
        } else if (this.displayFeaturesUsing === "simple") {
          generateSingleSymbol();
        }
      },
    },
  },
  beforeMount() {
    this.getGisDataFields();
  },
  methods: {
    updateSymbolUrl(rendererSymbol, index) {
      const shape =
        this.symbolChoices[index]?.match(
          new RegExp(symbols.map((s) => s.replace("LargeB", "")).join("|"))
        ) ?? "Circle";

      const newUrl = `/img/mapSymbols/${this.findColorValue(
        rendererSymbol.fill_color
      )}${shape}LargeB.png`;

      return newUrl;
    },
    async getGisDataFields() {
      const { mapServiceId } = this;
      if (!mapServiceId) {
        return;
      }
      const {
        data: { results },
      } = await axios.get(`${APIURL}/gis_data_fields`, {
        params: {
          map_service_id: mapServiceId,
        },
      });
      this.gisDataFields = results;
    },
    onRenderSymbolChange(index) {
      this.$set(this.rendererSymbols[index], "fill_color", "primary");
    },
    onRangeEdited(selectedRendererSymbol, index) {
      this.$set(this.rendererSymbols, index, selectedRendererSymbol);
      this.showEditValueRangeDialog = false;
      this.$emit("renderer-symbols-changed", this.rendererSymbols);
    },
    openEditValueRangeDialog(index) {
      this.selectedRendererSymbol = { ...this.rendererSymbols[index] };
      this.selectedRendererSymbolIndex = index;
      this.showEditValueRangeDialog = true;
    },
    deleteRendererSymbol(index) {
      this.rendererSymbols.splice(index, 1);
    },
    onRangeSet(range) {
      const { maxValue, minValue, label } = range;
      const symbol = {
        fill_color: "primary",
        label,
        max_value: maxValue,
        min_value: minValue,
        outline_color: "rgb(255, 255, 255)",
        picture_size: 32,
        renderer_symbol_id: uuidv4(),
        style: "Solid",
        symbol_type: "shape",
        url: "/img/mapSymbols/BlueCircleLargeB.png",
        width: 3,
        gis_data_field_option_id: null,
      };
      this.rendererSymbols.push(symbol);
      this.symbolChoices = this.rendererSymbols?.map(() => this.symbols[0]);
      this.showAddValueRangeDialog = false;
      this.$emit("renderer-symbols-changed", this.rendererSymbols);
    },
    findColorValue(actualColor) {
      return this.colorChoices.find((c) => c.actualColor === actualColor)
        ?.value;
    },
    async getGisDataFieldOptions() {
      const { fieldToReference } = this;
      if (!fieldToReference) {
        return;
      }
      const fieldId = this.gisDataFields?.find(
        (g) => g.name === fieldToReference
      )?.gis_data_field_id;

      if (!fieldId) {
        return;
      }
      const {
        data: {
          results: { gis_data_field_options: gisDataFieldOptions },
        },
      } = await axios.get(`${APIURL}/gis_data_fields/${fieldId}`);
      this.gisDataFieldOptions = gisDataFieldOptions;
      this.rendererSymbols = this.gisDataFieldOptions?.map((fo) => {
        const { label, gis_data_field_option_id: gisDataFieldOptionId } = fo;
        return {
          fill_color: "primary",
          label,
          max_value: 0,
          min_value: 0,
          outline_color: "rgb(255, 255, 255)",
          picture_size: 32,
          renderer_symbol_id: uuidv4(),
          style: "Solid",
          symbol_type: "shape",
          url: "",
          width: 3,
          gis_data_field_option_id: gisDataFieldOptionId,
        };
      });
      this.symbolChoices = this.rendererSymbols?.map(() => this.symbols[0]);
      this.$emit("renderer-symbols-changed", this.rendererSymbols);
    },
  },
};
</script>

<style scoped>
img {
  width: 40px;
}

.number-input {
  width: 20px;
}

.slider {
  width: 50%;
}

.menu-item {
  color: primary;
}

.value {
  width: 75px;
}
</style>
